import React from 'react'
import { Link } from 'gatsby'

import pic01 from '../assets/images/thanvilogo.png';

const Footer = (props) => (
    <footer id="footer">
        <section>
            <h2>Contact Details</h2>
            <dl className="alt">
                <dt>Timings</dt>
                <dd>Mon-Fri: 9AM to 6PM</dd>
                <dd>Sat-Sun: 9AM to 3PM</dd>
                <dt>Mobile</dt>
                <dd>(91) 98-2222-4441</dd>
                <dd>(91) 94-2218-0249</dd>
                <dt>Phone</dt>
                <dd>(91) 07263 - 252249</dd>
                <dd>(91) 07263 - 253419</dd>
                <dt>Email</dt>
                <dd><a target="_blank" href="mailto:thanvioffice@gmail.com">thanvioffice@gmail.com</a></dd>
            </dl>
        </section>
        <section>
            <h2>Address Details</h2>
            <dl className="alt">
              <dt>Address</dt>
              <dd>Shri Durga Trading Company,</dd>
              <dd>Rallies Plot, Near Khamgaon Bus Stand,</dd>
              <dd>Khamgaon 444 303</dd>
              <dd>Buldana, MH</dd>
            </dl>
            <ul className="icons">
                <li><b>Google Map Location  -</b> <a target="_blank" href="https://www.google.com/maps/place/Shri+Durga+Trading+Company/@20.7058771,76.5756477,15z/data=!4m5!3m4!1s0x0:0xe3b6f083b9b3d183!8m2!3d20.7058771!4d76.5756477" className="icon fa-map-marker alt"><span className="label">Google Map Location</span></a></li>
            </ul>
            {/*<ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
            </ul>*/}
        </section>
        <span className="image"><img src={pic01} width="30%" alt="" /></span>
    </footer>
)

export default Footer
